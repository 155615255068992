import { useState } from "react"
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { validateInput, validateInputs } from "../../../helpers/validation"
import awsconfig from "../../../aws-exports"

Amplify.configure(awsconfig)

const useResetPasswordForm = ({ code, username, successCallback }) => {
  const [error, setError] = useState({
    exists: false,
    message: "",
  })

  const [hasValidatedOnce, setHasValidatedOnce] = useState(false)
  const [inputs, setInputs] = useState({ password: "", password2: "" })
  const [inputErrors, setInputErrors] = useState({
    password: "",
    password2: "",
  })
  const [loading, setLoading] = useState(false)

  const handleInputChange = (event, ref) => {
    event.persist()
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))

    // if we already validated the form once (after first submit try),
    // update validation results in real time
    if (hasValidatedOnce) validateInput(ref, setInputErrors)
  }

  const handleError = ({ code, name, message }) => {
    let messageClean = message

    if (
      message.indexOf("length greater than or equal to 6") > -1 ||
      message.indexOf("Password not long enough") > -1
    )
      messageClean = "Your password must be at least 7 characters."

    setError({ exists: true, message: messageClean })
  }

  const handleSubmit = (event, inputRefs) => {
    if (event) {
      event.preventDefault()
      setHasValidatedOnce(true)
    }

    // validate each field individually
    if (validateInputs(inputRefs, setInputErrors) !== true) return

    if (inputs.password !== inputs.password2) {
      setInputErrors(errors => ({
        ...errors,
        password2: "passwords don't match",
      }))
      return
    }

    setLoading(true)

    Auth.forgotPasswordSubmit(username, code, inputs.password2)
      .then(data => {
        setLoading(false)
        successCallback(username)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        handleError(err, username)
      })
  }
  return {
    error,
    handleSubmit,
    handleInputChange,
    inputs,
    inputErrors,
    loading,
  }
}

export default useResetPasswordForm
