import React, { useState } from "react"
import { Link } from "gatsby"
import { Text } from "@thedgbrt/logology-ui-components"
import ResetPasswordForm from "./ResetPasswordForm"
import { getUriParameterByName } from "../../../helpers"

const ResetPassword = ({ location }) => {
  const [status, setStatus] = useState("resetPassword")

  const handleSubmitSuccess = async () => {
    setStatus("verification")
  }

  const code = getUriParameterByName("code", location)
  const email = getUriParameterByName("email", location)

  if (status === "resetPassword")
    return (
      <ResetPasswordForm
        successCallback={handleSubmitSuccess}
        username={email}
        code={code}
      />
    )

  return (
    <Text standalone>
      Your password has been reset, you can now{" "}
      <Link to={"/myaccount/proposals?email=" + encodeURIComponent(email)}>
        sign in
      </Link>
      .
    </Text>
  )
}

export default ResetPassword
