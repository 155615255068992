import React from "react"
import { GridBox, GridContainer, Title } from "@thedgbrt/logology-ui-components"
import SEO from "../components/seo"
import ResetPassword from "../components/auth/ResetPassword"

const ResetPasswordPage = props => (
  <>
    <SEO title="Reset password" />

    <GridBox colSpans={[[2, 6], [4, 7], [5, 6]]} withBgContainer>
      <Title standalone>New password</Title>
    </GridBox>

    <GridContainer
      rowsDesktop={["auto"]}
      rowsMobile={["auto"]}
      rowsTablet={["auto"]}
    >
      <GridBox colSpans={[[2, 6], [4, 7], [5, 6]]} withBorder>
        <ResetPassword location={props.location.href} />
      </GridBox>
    </GridContainer>
  </>
)

export default ResetPasswordPage
