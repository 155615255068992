import React from "react"
import {
  makeRem,
  Form,
  FormInput,
  FormSubmit,
  Spinner,
} from "@thedgbrt/logology-ui-components"
import { FormError } from "../../common/Form"
import useResetPasswordForm from "./useResetPasswordForm"

const ResetPasswordForm = ({ code, username, successCallback }) => {
  let formRef = React.createRef()
  let inputPasswordRef = React.createRef()
  let inputPassword2Ref = React.createRef()

  const {
    error,
    inputs,
    inputErrors,
    handleInputChange,
    handleSubmit,
    loading,
  } = useResetPasswordForm({ code, username, successCallback })

  return (
    <>
      {loading ? (
        <Spinner align="left" standalone />
      ) : (
        <>
          <form ref={formRef} style={{ paddingTop: makeRem(12) }}>
            <FormError error={error} />
            <Form standalone>
              <FormInput
                autoComplete="new-password"
                placeholder="My new password"
                type="password"
                name="password"
                error={inputErrors.password}
                onChange={e => handleInputChange(e, inputPasswordRef)}
                value={inputs.password}
                ref={inputPasswordRef}
                required
              />
              <FormInput
                autoComplete="new-password"
                placeholder="Confirm my new password"
                type="password"
                name="password2"
                error={inputErrors.password2}
                onChange={e => handleInputChange(e, inputPassword2Ref)}
                value={inputs.password2}
                ref={inputPassword2Ref}
                required
              />
              <FormSubmit
                text="Reset password"
                id="reset-password-submit"
                onClick={e =>
                  handleSubmit(e, [inputPasswordRef, inputPassword2Ref])
                }
              />
            </Form>
          </form>
        </>
      )}
    </>
  )
}

export default ResetPasswordForm
